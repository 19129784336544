import { sitePhrasing } from "../../Language/languageHandler"
import { CheckMarkIcon } from "../Icons/CheckMarkIcon"
import { ExclamationTriangle } from "../Icons/ExclamationTriangle"

let lastClick = 0

export default function UpsellProduct(props: {
    upsellProduct: {
        productId: string,
        name: string,
        price: number,
        originalPrice: number,
    },
    selectedProducts: any,
    updateCart: (selectedProduct: string) => void,
    proceedToCheckout: () => void,
    disabled: boolean,
}) {
    const {
        upsellProduct,
        selectedProducts,
        updateCart,
        proceedToCheckout,
        disabled,
    } = props

    const {
        productId,
        name,
        price,
        originalPrice,
    } = upsellProduct

    const savings = originalPrice - price


    const handleClick = () => {
        if (disabled) {
            return
        }
        const now = Date.now()
        if (now - lastClick < 300) {
            return
        }
        if (!selectedProducts[productId]) {
            updateCart(productId)
        }
        setTimeout(() => {
            proceedToCheckout()
        }, 200);
    }

    return (
        <div className="select-upsell-container">
            <div className="upsell-warning-title">
                <div className="upsell-warning-icon">
                    <ExclamationTriangle />
                </div>
                <div className="upsell-warning-text">
                    <h2 className="red">
                        {customTitles[productId] ?
                            customTitles[productId](name) :
                            `Your business may also be in need of ${name}`
                        }

                    </h2>
                </div>
            </div>
            {customDescriptions[productId] && (
                <div className="upsell-item-description">
                    <h3>
                        {customDescriptions[productId].title(name)}
                    </h3>
                    <ul>
                        {customDescriptions[productId].bullets.map((bullet: string, i: number) => (
                            <li key={i} dangerouslySetInnerHTML={{ __html: bullet }} />
                        ))}
                    </ul>
                </div>
            )}
            <div className="upsell-product-selection">
                <div className="product-info">
                    <div className="product-name">
                        <div className="selected-product-check">
                            <CheckMarkIcon />
                        </div>
                        <div className="product-name-text">
                            {name}
                        </div>
                    </div>

                    {originalPrice && (
                        <div className="price-description">
                            <div className="original-price">
                                ${(originalPrice / 100).toFixed(2)}
                            </div>
                            <div className="description red">
                                <div className="sale-price">
                                    ${(price / 100).toFixed(2)}
                                </div>

                                <div className="description-text">
                                    (discounted price only available now at checkout)
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="select-product-button" onClick={handleClick} style={{
                    opacity: disabled || (Date.now() - lastClick < 300) ? 0.5 : 1,
                    boxSizing: 'border-box',
                    maxWidth: originalPrice ? '570px' : '620px',
                }}>
                    {originalPrice ? (
                        <>
                            <div className={`save-now-text ${sitePhrasing.state && needsInverseHover[sitePhrasing.state] ? 'white-hover' : ''}`}>
                                Save ${savings / 100}!
                            </div>
                            <div className={`button-text ${sitePhrasing.state && needsInverseHover[sitePhrasing.state] ? 'white-hover' : ''}`}>
                                <b><i>Click here</i></b>&nbsp; to add to your cart
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={`save-now-text ${sitePhrasing.state && needsInverseHover[sitePhrasing.state] ? 'white-hover' : ''}`}>
                                Add to your cart now for ${price / 100}!
                            </div>
                            <div className={`button-text ${sitePhrasing.state && needsInverseHover[sitePhrasing.state] ? 'white-hover' : ''}`}>
                                <b><i>Click here</i></b>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="proceed-to-checkout-button-container">
                No thank you, &nbsp;<span className="no-thank-you-click" onClick={proceedToCheckout}>Click here</span>&nbsp;to continue to checkout
            </div>
        </div>
    )
}

const needsInverseHover: any = {
    'Georgia': true,
    'Tennessee': true,
    'South Carolina': true,
}

const customTitles: any = {
    'articlesOfIncorporation': (name: string) => `Add your ${name} to your order`,
    'certificateOfStatus': (name: string) => `Your business may also be in need of an updated ${name}`,
}

const customDescriptions: any = {
    'certificateOfStatus': {
        title: (name: string) => `What is a ${name}?`,
        bullets: [
            `It certifies that your ${sitePhrasing.state} business <u>is in existence,</u> is <u>authorized to transact business</u> in the state, and <u>complies with all state requirements</u>.`,
        ]
    },
    'laborLawPoster': {
        title: (name: string) => `What are ${name}?`,
        bullets: [
            `State and Federal mandated employment law notices that inform employees of their rights under the NLRA (National Labor Relations Act).`,
            `It is required by Federal & State Law to be posted in a visible location of the workplace for businesses with at least one employee.`,
        ]
    },
    'articlesOfIncorporation': {
        title: (name: string) => `What are the ${name}?`,
        bullets: [
            `The documents filed with the state to create your business which <u>serve as legal proof that your company is established</u> in your state`,
        ]
    },
}