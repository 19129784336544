import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { sitePhrasing } from '../../Language/languageHandler'
import { standardNetworkErrorCall, standardNetworkPendingCall } from '../reduxHelperFunctions'
import { RootState } from '../store'
import { productValues, updateCartInfo } from './shoppingHelpers'

// Define a type for the slice state
export interface ShoppingState {
  selectedProducts: any
  shippingMethod: string
  discount: number
  cartTotal: number
  shippingTotal: number
  step: number
  prepaid: false,
  // standard network request
  loading?: string
  currentRequestId?: string;
  error?: any
  message?: string;
}

// Define the initial state using that type
export const shoppingInitialState = (): ShoppingState => ({
  selectedProducts: {},
  shippingMethod: '',
  discount: 0,
  cartTotal: 0,
  shippingTotal: 0,
  prepaid: false,
  // step related
  step: 0,
  // standard network request
  loading: 'idle',
  error: null,
  currentRequestId: '',
  message: ''
})

export const proceedToCheckoutAction = createAsyncThunk(
  'shopping/proceedToCheckoutCall',
  async (formValues: any, thunkApi: any) => {
    const {
      loading,
      currentRequestId,
      selectedProducts,
      shippingMethod,
      shippingTotal,
      discount,
      prepaid
    } = thunkApi.getState().shopping
    const { requestId } = thunkApi
    if (loading !== 'pending' || requestId !== currentRequestId) { return }

    // Handle the form data
    const payload = {
      ...formValues,
      prepaid: prepaid,
      deliveryOption: shippingMethod,
      shippingPrice: shippingTotal,
      products: Object.keys(selectedProducts).map(p => {
        const {
          name, price
        } = selectedProducts[p]
        // } = productValues[p]
        return {
          productId: p,
          productName: name,
          productPrice: price,
          productQuantity: 1
        }
      }),
      discounts: discount ? [{
        discountType: 'amount_off',
        discountAmount: discount,
        discountId: 'comboSpecial'
      }] : []
    }
    
    if (!selectedProducts['annualReport'] && !selectedProducts['amend'] && !selectedProducts['reinstate']) {
      delete payload['annualReport']
    }
    if (!selectedProducts['einTaxId'] && !selectedProducts['legalNoticeAdvertisement']) {
      delete payload['einTaxInfo']
    }
    if (selectedProducts['legalNoticeAdvertisement'] && payload['businessName'] === '-' && payload['einTaxInfo']) {
      payload['businessName'] = payload['einTaxInfo'].about.tradeNameDBA
    }

    if (shippingMethod === 'physical' && formValues['shippingAddress']) {
      payload['shippingAddress'] = {
        name: formValues['shippingAddress'].name,
        address: {
          line1: formValues['shippingAddress'].address.address,
          line2: formValues['shippingAddress'].address.address2,
          city: formValues['shippingAddress'].address.city,
          state: formValues['shippingAddress'].address.state,
          postal_code: formValues['shippingAddress'].address.zip,
          country: 'US'
        }
      }
    } else {
      delete payload['shippingAddress']
    }

    // Make request
    const data = await fetch(`${sitePhrasing.serverUrl}/checkout/${sitePhrasing.paymentGateway === 'bankful' ? 'create-bankful-session' : 'create-checkout-session'}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        ...payload,
      }),
    }).then(res => {
      return res.json()
    }).catch(err => {
      console.log('something went wrong')
      return {
        error: err.toString()
      }
    })
    if (data.error) {
      return thunkApi.rejectWithValue(data)
    }
    if (data.url) {
      window.location.href = data.url
      return thunkApi.fulfilled({ message: 'Redirecting now...' })
    } else {
      return thunkApi.rejectWithValue({
        error: 'something went very wrong'
      })
    }
  }
)

export const shoppingSlice = createSlice({
  name: 'shopping',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: shoppingInitialState(),
  reducers: {
    prevStep: (state, action: PayloadAction<null>) => {
      if (state.step > -1) {
        // if (state.step > 0) {
        state.step--
      }
    },
    nextStep: (state, action: PayloadAction<null>) => {
      if (state.step < 3) {
        state.step++
      }
    },
    goToStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },
    updateCart: (state, action: PayloadAction<string>) => {
      return updateCartInfo(state, {
        product: action.payload
      })
    },
    updateCartUpsell: (state, action: PayloadAction<string>) => {
      return updateCartInfo(state, {
        product: action.payload,
        isUpsell: true
      })
    },
    updateProductCartInfo: (state, action: PayloadAction<any>) => {
      return updateCartInfo(state, {
        product: action.payload,
        updateProductInfo: true
      })
    },
    setShippingMethod: (state, action: PayloadAction<string>) => {
      return updateCartInfo(state, {
        shipping: action.payload
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(proceedToCheckoutAction.pending, standardNetworkPendingCall('Sending you to secure checkout page.'))
    builder.addCase(proceedToCheckoutAction.rejected, standardNetworkErrorCall())
    builder.addCase(proceedToCheckoutAction.fulfilled, (state, action: any) => {
      return {
        ...state,
        ...action.payload,
        loading: 'idle',
        message: '',
        error: null,
      }
    })
  }
})

export const {
  updateCart,
  updateCartUpsell,
  updateProductCartInfo,
  setShippingMethod,
  nextStep,
  prevStep,
  goToStep
} = shoppingSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectShopping = (state: RootState) => state.shopping

export default shoppingSlice.reducer
