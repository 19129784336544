// import { Link } from "react-router-dom";
import './cancelStyle.scss'

export default function CancelPage() {
  return (
    <div className="cancel-page">
      <h2>Your transaction was canceled</h2>
      <p>
        <a href="/services">Click here to return to the main page.</a>
        {/* <Link to="/services">Click here to return to the main page.</Link> */}
      </p>
    </div>
  )
}
