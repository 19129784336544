import { ExclamationTriangle } from "../Icons/ExclamationTriangle"
import './warningMessage.scss'

export const WarningMessage = (props: {
    message: string,
    iconWidth?: number,
}) => {
    const {
        message,
        iconWidth = 50,
    } = props
    return (
        <div className="warning-title">
            <div className="warning-icon" style={{ width: `${iconWidth}px` }}>
                <ExclamationTriangle />
            </div>
            <div className="warning-text">
                <div className="red">
                    {message}
                </div>
            </div>
        </div>
    )
}