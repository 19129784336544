import { BlackBox } from "../Icons/BlackBoxIcon";
import { CheckMarkIcon } from "../Icons/CheckMarkIcon";

export default function ProductSelection(props: {
  updateCart: (selectedProduct: string) => void;
  selectedProducts: any;
  checkBoxSize?: number;
  productValues: {
    [pId: string]: {
      name: string;
      price: number;
      deleteProducts?: string[];
      hideIfSelected?: string[];
      customClass?: string;
      lateFee?: number;
      lateFeeText?: string;
      comingSoon?: string;
      customName?: string;
      hidden?: boolean;
    };
  };
  showDescriptions?: boolean;
}) {
  const {
    updateCart,
    checkBoxSize,
    selectedProducts,
    productValues,
    showDescriptions,
  } = props;

  const productList: string[] = [];

  Object.keys(productValues).forEach((p: string, idx) => {
    const { hideIfSelected, hidden } = productValues[p];
    let show = true;
    if (hidden) {
      show = false;
    }
    if (hideIfSelected) {
      hideIfSelected.forEach((s) => {
        if (selectedProducts[s]) {
          show = false;
        }
      });
    }
    if (show) {
      productList.push(p);
    }
  });


  return (
    <div className="select-product-container">
      {productList &&
        productList.length > 0 &&
        productList.map((p: string, idx: number) => {
          const splitName = productValues[p].name.split("& ");
          const ProductDescription =
            showDescriptions && productDescriptions[p]
              ? productDescriptions[p]
              : null;
          const lateFee: any = productValues[p].lateFee
            ? productValues[p].lateFee
            : 0;
          return (
            <div
              key={`${p}-${idx}`}
              onClick={() => {
                if (productValues[p].comingSoon) {
                  return;
                }
                updateCart(p);
              }}
              className={`product-wrapper ${productValues[p].customClass} ${p} ${idx}`}
            >
              <div className="selected-product-marker">
                {selectedProducts[p] ? (
                  <CheckMarkIcon />
                ) : (
                  <BlackBox size={checkBoxSize ? checkBoxSize : 16} />
                )}
              </div>
              <div
                className={`product-entry ${
                  selectedProducts[p] ? "selected" : ""
                }`}
              >
                {productValues[p].customName ? (
                  <div>
                    <span
                      className="product-text"
                      dangerouslySetInnerHTML={{
                        __html: `${productValues[p].customName}`,
                      }}
                    />
                    <br />
                  </div>
                ) : (
                  splitName.map((x: string, idxn: number) => {
                    return (
                      <div key={`${x}-${idxn}-in`}>
                        <span className="product-text">
                          {x}
                          {idxn < splitName.length - 1 ? "& " : ""}
                        </span>
                        <br />
                      </div>
                    );
                  })
                )}
                {/* <span className="product-text">{productValues[p].name}</span>
              <br /> */}
                {!productValues[p].comingSoon && (
                  <>
                    <span className="price-arrow">►</span>{" "}
                    <span className="price-text">
                      ${productValues[p].price / 100}
                    </span>
                  </>
                )}
                {productValues[p].comingSoon && (
                  <span className="coming-soon-text">
                    {productValues[p].comingSoon}
                  </span>
                )}
                {lateFee > 0 && (
                  <span className="late-fee-text red-text">
                    {" "}
                    + ${lateFee / 100} late fee
                  </span>
                )}
                {ProductDescription && (
                  <div className="product-description-entry">
                    <ProductDescription />
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

const AnnualReportDescription = () => {
  return (
    <ul>
      <li>
        An annual report <span className="red">must be filed each year</span>{" "}
        for your business entity to maintain an “active status” with the
        Department of State.
      </li>
      <li>
        It updates or confirms your records with the Georgia Department of
        State, Division of Corporations, displaying your business’s most current
        data on file.
      </li>
      <li>
        <span className="red">
          It is required, whether or not you need to make changes.
        </span>
      </li>
      <li>
        It is <i>not</i> a financial statement.
      </li>
      <li>
        If a business files late, a <span className="red">$25 late fee</span>{" "}
        will be imposed on you by The State.
      </li>
      <li>
        If you do not file within 60 days of being late,{" "}
        <span className="red">
          your business entity may be administratively dissolved or revoked.
          Businesses may acquire additional reinstatement fees.
        </span>
      </li>
    </ul>
  );
};

const EinTaxIdDescription = () => {
  return (
    <ul>
      <li>
        An EIN (Employer Identification Number) is a nine-digit number issued by
        the IRS used to identify businesses and certain other entities. It is
        also called a Federal Tax ID.
      </li>
      <li>
        It is commonly used for taxing and banking purposes and to register your
        business with the federal government.
      </li>
      <li>
        You will need an EIN Tax ID in order to hire employees, open a business
        bank account, file taxes, and apply for business loans and/or lines of
        credit.
      </li>
      <li>
        <span className="red">
          All businesses are required to have an EIN Tax ID.
        </span>
      </li>
    </ul>
  );
};

const CertificateOfStatusDescription = () => {
  return (
    <ul>
      <li>
        <span className="red">
          It certifies that your Indiana business is in existence, is authorized
          to transact business in the state, and complies with all state
          requirements.
        </span>
      </li>
      <li>
        It may be required for loans, to renew business licenses, to open your
        business bank account, or for tax or other business purposes.
      </li>
      <li>
        It shows the official evidence of an entity's existence and provides a
        statement of an entity's status, legal name, and legal formation.
      </li>
      <li>It bears the official seal of the Indiana Secretary of State.</li>
    </ul>
  );
};

const LaborLawPosterDescription = () => {
  return (
    <ul>
      <li>
        <span className="red">
          Federal Law 29 USC Sec. 666 (I) & 29 Sec. 2005
        </span>{" "}
        states that an an up-to-date compliant Labor Law Poster{" "}
        <span className="red">must be posted</span> in a visible location on the
        property of business which has at least 1 employee.
      </li>
      <li>
        <span className="red">Penalties and risks</span> of non-compliance with
        posting regulations can lead to potential{" "}
        <span className="red">fines in excess of $7,000</span> per instance for
        failure to post federally required information, and could possibly even{" "}
        <span className="red">lead to lawsuits.</span>
      </li>
    </ul>
  );
};

const productDescriptions: any = {
  annualReport: AnnualReportDescription,
  einTaxId: EinTaxIdDescription,
  certificateOfStatus: CertificateOfStatusDescription,
  laborLawPoster: LaborLawPosterDescription,
};
