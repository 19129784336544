import { useState } from "react";
import { sitePhrasing } from "../../../../Language/languageHandler";
import { AddressInputFieldGroup } from "../../../FormFields/AddressFieldGroup/AddressInputFieldGroup";
import { ArrayFieldInput } from "../../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../../FormFields/RadioInputField/RadioInputField";
import TextInputField from "../../../FormFields/TextInputField/TextInputField";
import { blankRegisteredAgentFn, feiEinStatusOptions, registeredAgentFields, yesOrNoValues } from "../PurchaseConstants";
import { forcedRegistered, forcedStatePrincipal } from "../PurchaseFormHelpers";
import { ContactInfoFields } from "../ContactInfoFields";

export default function FloridaAnnualReportFields(props: {
    errors: any;
    touched: any;
    values: any;
    annualPhrasing?: string;
    setFieldValue: (field: string, value: any) => void;
}) {
    const {
        errors,
        touched,
        values,
        annualPhrasing = 'annual report',
        setFieldValue
    } = props;

    const [tableSearchValue, setTableSearchValue] = useState('')
    const [tableString, setTableString] = useState('')
    const [manualInput, setManualInput] = useState(false)

    const [detailsLoading, setDetailsLoading] = useState(false)

    const asyncSearch = async (documentId: string) => {

        if (documentId) {
            const {
                detailTable,
                parsed
            } = await fetch(`${sitePhrasing.serverUrl}/annual-info/${documentId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            }).then(res => {
                return res.json()
            }).then(data => {
                return data
            }).catch(err => {
                console.log('something went wrong')
                console.log(err)
                return {
                    error: true,
                    errorData: err
                }
            })
            return {
                detailTable,
                parsed
            }
        }
        return {}
    }

    const handleSearch = async () => {
        const searchValue = values.referenceNumber
        if (tableSearchValue === searchValue) { return }
        setDetailsLoading(true)
        setTableSearchValue(searchValue)
        setTableString('')
        setFieldValue('annualReport.informationStillAccurate', '')
        setFieldValue('annualReport.isThisYourInformation', '')
        setFieldValue('businessName', '')
        setFieldValue('annualReport.feiEin', '')
        setFieldValue('annualReport.feiEinStatus', '')
        setFieldValue('annualReport.sunbizPayload', {})
        const { detailTable, parsed = {} } = await asyncSearch(searchValue)

        console.log(parsed) 
        // MAKE SURE YOU ADD THE FOLLOWING FIELDS to the annualReport object in the backend and frontend
        // sunbizPayload
        // informationStillAccurate
        // isThisYourInformation 

        setDetailsLoading(false)
        if (detailTable && parsed && parsed['Corporation Name']) {
            setTableString(detailTable)
            // extract fictitious name from table
            const businessName = parsed['Corporation Name']
            setFieldValue('businessName', businessName)

            if (parsed['Filing Information'] && parsed['Filing Information']['FEI/EIN Number']) {
                const feiEin = parsed['Filing Information']['FEI/EIN Number']
                setFieldValue('annualReport.feiEin', feiEin)
                setFieldValue('annualReport.feiEinStatus', 'listed')
            }

            setFieldValue('annualReport.sunbizPayload', parsed)
            setManualInput(false)
            return
        }

        setTableString('error')
        setManualInput(false)
    }

    const handleManualMode = () => {
        setManualInput(true)
        setTableString('')
        setTableSearchValue('')
        setFieldValue('businessName', '')
        setFieldValue('annualReport.feiEin', '')
        setFieldValue('annualReport.feiEinStatus', '')
        setFieldValue('annualReport.sunbizPayload', {})
        setFieldValue('annualReport.isThisYourInformation', 'no')
        setFieldValue('annualReport.informationStillAccurate', 'no')
    }

    let needsAnnual = true;


    return (<>


        <div
            className="reference-number-lookup"
            style={{
                padding: `10px 20px`,
                border: `4px solid #000`,
                marginBottom: `20px`,
            }}
        >
            Enter your reference number to auto-fill your business information

            <TextInputField
                mask={sitePhrasing.referenceMask}
                fieldName={'referenceNumber'}
                fieldLabel={'Reference Number'}
                fieldSubLabel={'(This number can be found on the top right hand corner of your letter)'}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={'Reference Number'}
            />

            <button
                onClick={handleSearch}
                type={'button'}
                role={'button'}
                className={`step-btn btn${!values.referenceNumber || errors.referenceNumber ? ' disabled-btn' : ''}`}
                disabled={!values.referenceNumber || errors.referenceNumber}
            >
                Search
            </button>
            {detailsLoading && (
                <div className="loading-spinner">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
            {!tableString && !detailsLoading && errors.businessName && !errors.iAgree && (
                <div className="error" style={{ color: 'red', marginTop: '10px', marginBottom: '10px' }}>
                    Please enter a valid reference number and click search.
                </div>
            )}
            {tableString === 'error' && (
                <div className="error" style={{ color: 'red', marginTop: '10px', marginBottom: '10px' }}>
                    The reference number you have entered does not match any business names currently on record. Please check the number and try again, or
                    &nbsp;
                    <div
                        style={{ display: 'inline-block', color: 'blue', cursor: 'pointer' }}
                        onClick={() => {
                            handleManualMode()
                        }}
                    >
                        click here
                    </div>
                    &nbsp;
                    to enter your information manually.
                </div>
            )}

        </div>

        {values?.annualReport?.sunbizPayload['Corporation Name'] && (
            <div className="annual-info-payload">
                <h3>Business Name</h3>
                {values?.annualReport?.sunbizPayload['Corporation Name']} <br />

                <h3>Filing Information</h3>
                {Object.keys(values?.annualReport?.sunbizPayload['Filing Information']).map((key, index) => (
                    <div key={`filing-info-${index}`}>
                        <b> {key}:</b> {values?.annualReport?.sunbizPayload['Filing Information'][key]}
                    </div>
                ))}

                <h3>Principal Address</h3>
                {values?.annualReport?.sunbizPayload['Principal Address'] && (
                    <AddressMarkup addressInfo={values?.annualReport?.sunbizPayload['Principal Address']} />
                )}

                <h3>Mailing Address</h3>
                {values?.annualReport?.sunbizPayload['Mailing Address'] && (
                    <AddressMarkup addressInfo={values?.annualReport?.sunbizPayload['Mailing Address']} />
                )}

                <h3>Registered Agent Name & Address</h3>
                {values?.annualReport?.sunbizPayload['Registered Agent'] && (
                    <>
                        {values?.annualReport?.sunbizPayload['Registered Agent']['name']} <br />
                        <AddressMarkup addressInfo={values?.annualReport?.sunbizPayload['Registered Agent']} />
                    </>
                )}

                <h3>Authorized Person(s) Detail</h3>
                {values?.annualReport?.sunbizPayload['Authorized Persons'] && (
                    <>
                        {values?.annualReport?.sunbizPayload['Authorized Persons'].map((agent: any, index: number) => (
                            <div key={`agent-${index}`}>
                                <b>Name:</b> {agent['name']} <br />
                                <b>Title:</b> {agent['title']} <br />
                                <AddressMarkup addressInfo={agent} /><br />
                            </div>
                        ))}
                    </>
                )}
            </div>
        )}

        {manualInput && (
            <>
                <TextInputField
                    mask={sitePhrasing.referenceMask}
                    fieldName={'referenceNumber'}
                    fieldLabel={'Reference Number'}
                    fieldSubLabel={'(This number can be found on the top right hand corner of your letter)'}
                    errors={errors}
                    touched={touched}
                    required={true}
                    placeholder={'Reference Number'}
                />

                <TextInputField
                    fieldName={'businessName'}
                    fieldLabel={'Complete Business Name'}
                    errors={errors}
                    required={true}
                    touched={touched}
                    placeholder={'Complete Business Name'}
                />
            </>
        )}

        {needsAnnual && tableString && tableString !== 'error' && (
            <div
                className="important-radio-input"
                style={{
                    padding: `10px 10px 0px 10px`,
                    border: `4px solid #000`,
                    marginBottom: `20px`,
                }}
            >
                <RadioInputField
                    fieldName={'annualReport.isThisYourInformation'}
                    fieldLabel={'Is the information shown above yours?'}
                    errors={errors}
                    required={true}
                    touched={touched}
                    customClass={`full-width`}
                    options={yesOrNoValues}
                />

                {values.annualReport.isThisYourInformation === 'no' && (
                    <div className="error" style={{ color: 'red', marginTop: '10px', marginBottom: '10px' }}>
                        Please check the reference number you entered and try again, or
                        &nbsp;
                        <div
                            style={{ display: 'inline-block', color: 'blue', cursor: 'pointer' }}
                            onClick={() => {
                                handleManualMode()
                            }}
                        >
                            click here
                        </div>
                        &nbsp;
                        to enter your information manually.
                    </div>
                )}

                {values.annualReport.isThisYourInformation === 'yes' && (
                    <RadioInputField
                        fieldName={'annualReport.informationStillAccurate'}
                        fieldLabel={'Is the information above still accurate?'}
                        errors={errors}
                        required={true}
                        touched={touched}
                        customClass={`full-width`}
                        options={[
                            { value: 'yes', label: 'Yes, all my information is still the same.' },
                            { value: 'no', label: 'No, I need to update my information' },
                        ]}
                        onChange={(e: any) => {
                            if (e.target.value === 'yes') {
                                setFieldValue('annualReport.informationChange.principalAddress.changed', 'no')
                                setFieldValue('annualReport.informationChange.mailingAddress.changed', 'no')
                                setFieldValue('annualReport.informationChange.registeredAgent.agentChange', 'no')
                                setFieldValue('annualReport.informationChange.registeredAgent.agentAddressChange', 'no')
                                setFieldValue('annualReport.informationChange.authorizedPerson.authorizedPersonChange', 'no')
                                setFieldValue('annualReport.informationChange.authorizedPerson.removingAgents', 'no')
                                setFieldValue('annualReport.informationChange.authorizedPerson.addingAgents', 'no')
                            } else {
                                setFieldValue('annualReport.informationChange.principalAddress.changed', '')
                                setFieldValue('annualReport.informationChange.mailingAddress.changed', '')
                                setFieldValue('annualReport.informationChange.registeredAgent.agentChange', '')
                                setFieldValue('annualReport.informationChange.registeredAgent.agentAddressChange', '')
                                setFieldValue('annualReport.informationChange.authorizedPerson.authorizedPersonChange', '')
                                setFieldValue('annualReport.informationChange.authorizedPerson.removingAgents', '')
                                setFieldValue('annualReport.informationChange.authorizedPerson.addingAgents', '')
                            }
                            setFieldValue('annualReport.informationStillAccurate', e.target.value)
                        }}
                    />
                )}
            </div>
        )}

        {values.annualReport.informationStillAccurate === 'no' && (
            <>

                <h3>Do you need to change any of the following information?</h3>
                <RadioInputField
                    fieldName={'annualReport.informationChange.principalAddress.changed'}
                    fieldLabel={'Principal Address?'}
                    errors={errors}
                    required={true}
                    touched={touched}
                    options={yesOrNoValues}
                />
                {values.annualReport.informationChange.principalAddress.changed === 'yes' && (
                    <AddressInputFieldGroup
                        fieldName={'annualReport.informationChange.principalAddress.newAddress'}
                        errors={errors}
                        touched={touched}
                        fieldMessages={{
                            state: forcedStatePrincipal[sitePhrasing.state] ? `Your principal address must be located in ${sitePhrasing.state}.` : ``
                        }}
                        disableFields={{
                            state: forcedStatePrincipal[sitePhrasing.state] ? true : false,
                        }}
                    />
                )}

                <RadioInputField
                    fieldName={'annualReport.informationChange.mailingAddress.changed'}
                    fieldLabel={'Mailing Address?'}
                    errors={errors}
                    required={true}
                    touched={touched}
                    options={yesOrNoValues}
                />
                {values.annualReport.informationChange.mailingAddress.changed === 'yes' && (
                    <AddressInputFieldGroup
                        fieldName={'annualReport.informationChange.mailingAddress.newAddress'}
                        errors={errors}
                        touched={touched}
                    />
                )}


                <RadioInputField
                    fieldName={'annualReport.informationChange.registeredAgent.agentChange'}
                    fieldLabel={'Registered Agent?'}
                    errors={errors}
                    required={true}
                    touched={touched}
                    options={yesOrNoValues}
                />
                {values.annualReport.informationChange.registeredAgent.agentChange === 'yes' && (
                    <TextInputField
                        fieldName={'annualReport.informationChange.registeredAgent.newAgent'}
                        fieldLabel={'Registered Agent Name'}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={'Registered Agent Name'}
                    />
                )}

                <RadioInputField
                    fieldName={'annualReport.informationChange.registeredAgent.agentAddressChange'}
                    fieldLabel={'Registered Agent Address?'}
                    errors={errors}
                    required={true}
                    touched={touched}
                    options={yesOrNoValues}
                />
                {values.annualReport.informationChange.registeredAgent.agentAddressChange === 'yes' && (
                    <AddressInputFieldGroup
                        fieldName={'annualReport.informationChange.registeredAgent.newAddress'}
                        errors={errors}
                        touched={touched}
                        fieldMessages={{
                            state: forcedRegistered[sitePhrasing.state] ? `Your registered agent must be located in ${sitePhrasing.state}.` : ``
                        }}
                        disableFields={{
                            state: forcedRegistered[sitePhrasing.state] ? true : false,
                        }}
                    />
                )}

                <RadioInputField
                    fieldName={'annualReport.informationChange.authorizedPerson.authorizedPersonChange'}
                    fieldLabel={sitePhrasing.state === 'Florida' ? `Person(s) Authorized to manage the ${sitePhrasing.business}?` : 'Company Officials?'}
                    errors={errors}
                    required={true}
                    touched={touched}
                    options={yesOrNoValues}
                />
                {values.annualReport.informationChange.authorizedPerson.authorizedPersonChange === 'yes' && (
                    <>
                        <RadioInputField
                            fieldName={'annualReport.informationChange.authorizedPerson.removingAgents'}
                            fieldLabel={`Are you removing any ${sitePhrasing.state === 'Florida' ? 'agents' : 'Company Officials'}?`}
                            errors={errors}
                            required={true}
                            touched={touched}
                            options={yesOrNoValues}
                        />
                        {values.annualReport.informationChange.authorizedPerson.removingAgents === 'yes' && (
                            <ArrayFieldInput
                                fieldName={'annualReport.informationChange.authorizedPerson.removedAgents'}
                                values={values.annualReport.informationChange.authorizedPerson.removedAgents}
                                blankEntryFn={blankRegisteredAgentFn}
                                fields={registeredAgentFields}
                                touched={touched}
                                errors={errors}
                                removeText={'- Click to remove entry'}
                                addText={'+ Click to add an entry'}
                            />
                        )}

                        <RadioInputField
                            fieldName={'annualReport.informationChange.authorizedPerson.addingAgents'}
                            fieldLabel={`Are you adding any ${sitePhrasing.state === 'Florida' ? 'agents' : 'Company Officials'}?`}
                            errors={errors}
                            required={true}
                            touched={touched}
                            options={yesOrNoValues}
                        />
                        {values.annualReport.informationChange.authorizedPerson.addingAgents === 'yes' && (
                            <ArrayFieldInput
                                fieldName={'annualReport.informationChange.authorizedPerson.addedAgents'}
                                values={values.annualReport.informationChange.authorizedPerson.addedAgents}
                                blankEntryFn={blankRegisteredAgentFn}
                                fields={registeredAgentFields}
                                touched={touched}
                                errors={errors}
                                removeText={'- Click to remove entry'}
                                addText={'+ Click to add an entry'}
                            />
                        )}

                        <RadioInputField    
                            fieldName={'annualReport.informationChange.authorizedPerson.changingAddress'}
                            fieldLabel={`Changing Address?`}
                            errors={errors}
                            required={true}
                            touched={touched}
                            options={yesOrNoValues}
                        />

                        {values.annualReport.informationChange.authorizedPerson.changingAddress === 'yes' && (
                            <AddressInputFieldGroup
                                fieldName={'annualReport.informationChange.authorizedPerson.changedAddress'}
                                errors={errors}
                                touched={touched}
                            />
                        )}


                    </>
                )}
            </>
        )}

        <h3>Contact Information</h3>

        <ContactInfoFields
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
        />

        <TextInputField
            mask={[/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            fieldName={'annualReport.feiEin'}
            fieldLabel={'FEI/EIN Number'}
            errors={errors}
            touched={touched}
            required={false}
            placeholder={'FEI/EIN Number'}
        />

        <RadioInputField
            fieldName={'annualReport.feiEinStatus'}
            fieldLabel={'FEI/EIN Number Status'}
            errors={errors}
            required={true}
            touched={touched}
            options={feiEinStatusOptions}
            customClass={'block-radio'}
        />

        <div className="field-note">
            If you have not yet obtained your EIN Federal Tax ID, you may do so now by <a href="/" target="_blank" className="link-text">clicking here</a>.
        </div>


        <div>
            <CheckboxInputField
                fieldName={'annualReport.noPublicRecordExemption'}
                fieldLabel={'I hereby certify that the information supplied with this filing does not qualify for the public records exemptions contained in Chapter 119, Florida Statutes.'}
                touched={touched}
                errors={errors}
            />

            <CheckboxInputField
                fieldName={'annualReport.tellingTruth'}
                fieldLabel={`I hereby certify that the information indicated on this report or supplemental report is true and accurate and that my electronic signature shall have the same legal effect as if made under oath; that I am authorized to execute this report as required by Chapter 605, Florida Statutes; I am aware that false information submitted in a document to the Department of State constitutes a third degree felony as provided for in s. 817.155 , F.S.`}
                touched={touched}
                errors={errors}
                customLabel={() => (
                    <>
                        I hereby certify that the information indicated on this report or
                        supplemental report is true and accurate and that my electronic signature
                        shall have the same legal effect as if made under oath; that I am authorized to
                        execute this report as required by Chapter 605, Florida Statutes; I am aware that
                        false information submitted in a document to the Department of State constitutes a
                        third degree felony as provided for in s.
                        &nbsp;
                        <a className="link-text" target="_blank" href="http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&Search_String=&URL=0800-0899/0817/Sections/0817.155.html">
                            817.155
                        </a>
                        &nbsp;, F.S.
                    </>
                )}
            />
            <CheckboxInputField
                fieldName={'annualReport.iAmAuthorized'}
                fieldLabel={'This signature must be that of the individual "signing" this document electronically or be made with the full knowledge and permission of the individual, otherwise it constitutes forgery under s. 831.06 , F.S. The individual "signing" this document affirms that the facts stated herein are true.'}
                touched={touched}
                errors={errors}
            />
            <CheckboxInputField
                fieldName={'annualReport.annualAgree'}
                fieldLabel={`With my digital signature, (i) I represent and warrant that all of the information provided above is accurate and complete; (ii) I agree that I have carefully read and agree to be bound by the Terms of Service, Refund Policy, and Privacy Policy (see below) and (iii) I have read and understand that I am hereby authorizing Florida Certified Document Services as a "Third Party Designee" to file my ${annualPhrasing} on my behalf using the information I provided. I agree to the terms & conditions and authorize this payment to Florida Certified Document Services. I hereby certify that the information above is correct and that I am authorized to conduct this transaction.`}
                touched={touched}
                errors={errors}
            />
            <br />
        </div>

        <TextInputField
            fieldName={'annualReport.electronicSignature'}
            fieldLabel={'Name of Individual Signing - Electronic Signature'}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={'Electronic Signature'}
            customClass={'signature-font'}
        />
        <TextInputField
            fieldName={'annualReport.title'}
            fieldLabel={'Title'}
            errors={errors}
            touched={touched}
            required={true}
            placeholder={'Title'}
        />



    </>)
}

const AddressMarkup = (props: {
    addressInfo: {
        address: string;
        address2?: string;
        city: string;
        state: string;
        zip: string;
    }
}) => {
    const {
        addressInfo = {} as any
    } = props;

    const {
        address = '',
        address2 = '',
        city = '',
        state = '',
        zip = ''
    } = addressInfo;

    return (
        <>
            {address} <br />
            {address2 && (
                <>
                    {address2} <br />
                </>
            )}
            {city}, {state} {zip}
            <br />
        </>
    )
}