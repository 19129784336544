import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { getInitialState } from './getInitialState'
import shoppingSlice from './Shopping/shoppingSlice'

const initialState = getInitialState()

export const store = configureStore({
    devTools: true,
    preloadedState: initialState,
    reducer: {
      shopping: shoppingSlice
    },
})

export const useAppDispatch = () => useDispatch<AppDispatch>()
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
