import * as yup from 'yup'

export const generateBillingShippingFormInitialValues = () => {
    return {
        shippingSameAsBilling: '',
        shippingAddress: {
            address: {
                address: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            },
            name: ''
        },
        billingInfo: {
            firstName: '',
            lastName: '',
            address: {
                address: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
            },
        },
    }
}

export const generateBillingShippingValidationSchema = (
    shippingRequired: boolean,
    billingInfoRequired: boolean
) => yup.object().shape({

    ...(shippingRequired ? {
        shippingAddress: yup.object().shape({
            address: yup.object().shape({
                address: yup.string().required('Required'),
                city: yup.string().required('Required'),
                state: yup.string().required('Required'),
                zip: yup.string().required('Required'),
            }),
            name: yup.string().required('Required'),
        }),
    } : {}),

    ...(billingInfoRequired ? {
        billingInfo: yup.object().shape({
            address: yup.object().shape({
                address: yup.string().required('Required'),
                city: yup.string().required('Required'),
                state: yup.string().required('Required').length(2, 'Must be 2 characters'),
                zip: yup.string().required('Required'),
            }),
            firstName: yup.string().required('Required'),
            lastName: yup.string().required('Required'),
        }),
    } : {}),

})
