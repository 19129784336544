export const checkVal = (checkObject: any, accessor: string) => {
  let valRef = checkObject
  accessor.split(/[.[\]]/).forEach((x) => {
    if(!x){ return }
    if(valRef && valRef[x]) {
      valRef = valRef[x]
    } else {
      valRef = false
    }
  })
  return valRef
}
