import TextInputField from "../TextInputField/TextInputField"

export const AddressInputFieldGroup = (props: {
  fieldName?: string,
  errors: any,
  touched: any,
  message?: string,
  disableFields?: {
    address?: boolean,
    address2?: boolean,
    city?: boolean,
    state?: boolean,
    zip?: boolean
  },
  fieldMessages?: {
    address?: string,
    address2?: string,
    city?: string,
    state?: string,
    zip?: string
  }
}) => {
  const {
    fieldName,
    errors,
    touched,
    message,
    disableFields,
    fieldMessages
  } = props
  return (
    <>
      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}address`}
        fieldLabel={'Street'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'Street'}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}address2`}
        fieldLabel={'Apt#'}
        errors={errors}
        touched={touched}
        placeholder={'Apt#'}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}city`}
        fieldLabel={'City'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'City'}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}state`}
        fieldLabel={'State'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'State'}
        disabled={disableFields?.state}
        fieldSubLabel={fieldMessages?.state}
      />

      <TextInputField
        fieldName={`${fieldName ? `${fieldName}.` : ''}zip`}
        fieldLabel={'Zip Code'}
        errors={errors}
        touched={touched}
        required={true}
        placeholder={'Zip Code'}
      />
    </>
  )
}
