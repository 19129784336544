import TextInputField from "../../FormFields/TextInputField/TextInputField"

export const ContactInfoFields = (props: {
    errors: any,
    touched: any,
    values: any,
    setFieldValue: (field: string, value: any) => void,
}) => {
    const {
        values,
        errors,
        touched,
        setFieldValue
    } = props
    return (
        <>
            <TextInputField
                fieldName={'firstName'}
                fieldLabel={'First Name'}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={'First Name'}
            />

            <TextInputField
                fieldName={'lastName'}
                fieldLabel={'Last Name'}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={'Last Name'}
            />

            <TextInputField
                fieldName={'email'}
                fieldLabel={'Email'}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={'Email'}
            />

            <TextInputField
                mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                fieldName={'phoneNumber'}
                fieldLabel={'Phone Number'}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={'Phone Number'}
            />
        </>
    )
}