import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import CartInformation from "../../Components/CartInformation/CartInformation";
import PurchaseForm from "../../Components/Forms/PurchaseForm/PurchaseForm";
import ProductSelection from "../../Components/ProductSelection/ProductSelection";
import ShippingSelection from "../../Components/ShippingSelection/ShippingSelection";
import { sitePhrasing } from "../../Language/languageHandler";
import {
  customShippingOptions,
  productValues,
  shippingOptions,
  specialPricingRequired,
  updateProductPrice,
} from "../../Redux/Shopping/shoppingHelpers";
import {
  nextStep,
  prevStep,
  proceedToCheckoutAction,
  selectShopping,
  setShippingMethod,
  updateCart,
  updateCartUpsell,
  updateProductCartInfo,
} from "../../Redux/Shopping/shoppingSlice";
import { useAppDispatch } from "../../Redux/store";
import "./purchaseStyles2.scss";
import BillingShippingForm from "../../Components/Forms/BillingShippingForm/BillingShippingForm";
import UpsellProduct from "../../Components/UpsellProduct/UpsellProduct";
import CheckSelection from "../../Components/CheckSelection/CheckSelection";

const BACK_STEP_TEXT = "Back";
const NEXT_STEP_TEXT = "Click here to continue";

export default function PurchasePage2() {
  const {
    selectedProducts,
    shippingMethod,
    loading,
    message,
    error,
    step,
    prepaid,
  } = useSelector(selectShopping);
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<any>({});
  
  // Mississipi requires special pricing
  const siteState = sitePhrasing.state;
  const [specialPricingSet, setSpecialPricingSet] = useState<any>("");
  let specialPricingValues: any = {};
  if (siteState && specialPricingRequired[siteState]) {
    Object.keys(productValues).forEach((product) => {
      if (specialPricingRequired[siteState][product]) {
        specialPricingValues = specialPricingRequired[siteState][product];
      }
    });
  }
  // end special pricing

  const shippingAddressRequired =
    sitePhrasing.paymentGateway === "bankful" && shippingMethod === "physical"
      ? true
      : false;
  const billingInfoRequired =
    !prepaid && sitePhrasing.paymentGateway === "bankful" ? true : false;

  const topRef = useRef<HTMLDivElement>(null);

  const updateShoppingCart = (product: string) => {
    dispatch(updateCart(product));
  };

  const updateShoppingCartUpsell = (product: string) => {
    dispatch(updateCartUpsell(product));
  };

  const updateProductInfo = (product: string) => {
    dispatch(updateProductCartInfo(product));
  };

  const upsellProducts: any = {};

  Object.keys(selectedProducts).forEach((product) => {
    const productValue = selectedProducts[product];
    if (
      productValue &&
      productValue.upsell &&
      productValue.upsell.productId &&
      (!selectedProducts[productValue.upsell.productId] ||
        selectedProducts[productValue.upsell.productId].isUpsell)
    ) {
      upsellProducts[productValue.upsell.productId] = productValue.upsell;
    }
  });
  if (Object.keys(upsellProducts).length === 0) {
    Object.keys(productValues).forEach((product) => {
      const productValue = productValues[product];
      if (
        productValue &&
        productValue.offerAsUpsell &&
        (!selectedProducts[product] || selectedProducts[product].isUpsell)
      ) {
        upsellProducts[product] = productValue;
      }
    });
  }

  const submitForm = (values: any) => {
    if (upsellProducts && Object.keys(upsellProducts).length > 0) {
      setFormValues({
        ...values,
      });
      dispatch(nextStep(null));
      setTimeout(() => {
        if (topRef && topRef.current) {
          // scroll to top of div
          topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1);
      return;
    }

    if (shippingAddressRequired || billingInfoRequired) {
      setFormValues({
        ...values,
      });
      dispatch(nextStep(null));
      setTimeout(() => {
        if (topRef && topRef.current) {
          // scroll to top of div
          topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1);
      return;
    }
    dispatch(proceedToCheckoutAction(values));
  };

  const handleBillingShippingSubmit = (values: any) => {
    const fullFormValues = {
      ...formValues,
      ...values,
    };
    if (!shippingAddressRequired) {
      delete fullFormValues.shippingAddress;
      delete fullFormValues.shippingSameAsBilling;
    }
    if (!billingInfoRequired) {
      delete fullFormValues.billingInfo;
    }
    dispatch(
      proceedToCheckoutAction({
        ...fullFormValues,
      })
    );
  };

  const handleUpsellSubmit = () => {
    // add some logic here in case they need to hit the shipping form
    dispatch(
      proceedToCheckoutAction({
        ...formValues,
      })
    );
  };

  let customShippingValues;
  if (
    selectedProducts["legalNoticeAdvertisement"] &&
    customShippingOptions["legalNoticeAdvertisement"]
  ) {
    customShippingValues = customShippingOptions["legalNoticeAdvertisement"];
  }

  return (
    <div className="split-page-container">
      <div ref={topRef} className="split-page-info-section">
        {step < 1 && (
          <>
            {/* <h1>New Businesses</h1> */}
            <h1>
              {sitePhrasing.step1Title
                ? sitePhrasing.step1Title
                : "New Businesses"}
            </h1>
            {/* <h3>Select/Deselect your documents <br /> and press continue</h3> */}
            {sitePhrasing.step1ExtraText && (
              <h3 className="site-phrasing-step-1-extra-text">
                {sitePhrasing.step1ExtraText}
              </h3>
            )}
          </>
        )}

        {step === 1 && Object.keys(selectedProducts).length > 0 && (
          <>
            <h1>Delivery Options</h1>
            <h3>How would you like to receive your documents?</h3>
            <p>Please select one:</p>
          </>
        )}

        {step >= 2 && Object.keys(selectedProducts).length > 0 && (
          // {step === 2 && Object.keys(selectedProducts).length > 0 && (
          <>
            <h1>
              Document <br /> Request Form
            </h1>
            <h3>
              Please fill out correctly and completely to proceed to payment
              screen
            </h3>
          </>
        )}

        <div className="cart-information">
          {step > -1 && <CartInformation />}

          {((step === -1 && specialPricingSet) ||
            (step === 0 && Object.keys(selectedProducts).length > 0)) && (
            <button
              className="step-btn btn"
              onClick={() => dispatch(nextStep(null))}
            >
              {" "}
              {NEXT_STEP_TEXT}{" "}
            </button>
          )}
        </div>
      </div>

      <div className="split-page-action-section">
        <div className="split-page-action-information">
          {step === -1 && Object.keys(specialPricingValues).length > 0 && (
            <div className="purchase-step">
              <div className="product-cart-container">
                <h3
                  className="select-deselect-title"
                  style={{
                    marginTop: "auto",
                    marginBottom: "30px",
                    textAlign: "left",
                  }}
                >
                  {specialPricingValues.sectionTitle}
                </h3>
                <CheckSelection
                  selectedValues={
                    specialPricingSet ? { [specialPricingSet]: true } : {}
                  }
                  onChange={(key: string) => {
                    if (
                      !key ||
                      !specialPricingValues.values ||
                      !specialPricingValues.values[key]
                    ) {
                      return;
                    }
                    const selectedValue = specialPricingValues.values[key];
                    updateProductPrice(
                      specialPricingValues.productKey,
                      selectedValue.price
                    );
                    updateProductInfo(specialPricingValues.productKey);
                    setSpecialPricingSet(key);
                  }}
                  selectionValues={specialPricingValues.values}
                  checkBoxSize={18}
                />
              </div>
              <div className="button-step-wrapper-grid">
                <div className="empty-drv" />
                {specialPricingSet && (
                  <button
                    className="step-btn btn"
                    onClick={() => {
                      dispatch(nextStep(null));
                      setTimeout(() => {
                        if (topRef && topRef.current) {
                          // scroll to top of div
                          topRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }
                      }, 1);
                    }}
                  >
                    {" "}
                    {NEXT_STEP_TEXT}{" "}
                  </button>
                )}
              </div>
            </div>
          )}

          {step === 0 && (
            <div className="purchase-step">
              {Object.keys(specialPricingValues).length > 0 && (
                <div
                  className="button-step-wrapper"
                  style={{ marginBottom: "20px" }}
                >
                  {Object.keys(selectedProducts).length > 0 && (
                    <button
                      className="step-btn btn back-btn"
                      onClick={() => dispatch(prevStep(null))}
                    >
                      {" "}
                      {BACK_STEP_TEXT}{" "}
                    </button>
                  )}
                </div>
              )}

              {sitePhrasing.customProductSelectMessage && (
                <div
                  className="custom-product-select-message"
                  dangerouslySetInnerHTML={{
                    __html: sitePhrasing.customProductSelectMessage,
                  }}
                />
              )}

              <div className="product-cart-container">
                <h3
                  className="select-deselect-title"
                  style={{
                    marginTop: "auto",
                    marginBottom: "30px",
                    textAlign: "left",
                  }}
                >
                  Select/Deselect your documents <br /> and press continue
                </h3>

                <ProductSelection
                  updateCart={updateShoppingCart}
                  selectedProducts={selectedProducts}
                  checkBoxSize={18}
                  showDescriptions={false}
                  productValues={productValues}
                />
                {/* 
                    <div className="totals-wrapper">
                    <div className="totals-inner">
                    <CartInformation />
                    </div>
                  </div> 
              */}
              </div>

              <div className="cart-information">
                <CartInformation />

                {/* {step === 0 && Object.keys(selectedProducts).length > 0 && (
                    <button className="step-btn btn" onClick={() => dispatch(nextStep(null))}> {NEXT_STEP_TEXT} </button>
                )} */}
              </div>

              <div className="button-step-wrapper-grid">
                <div className="empty-drv" />
                {Object.keys(selectedProducts).length > 0 && (
                  <button
                    className="step-btn btn"
                    onClick={() => {
                      dispatch(nextStep(null));
                      setTimeout(() => {
                        if (topRef && topRef.current) {
                          // scroll to top of div
                          topRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }
                      }, 1);
                    }}
                  >
                    {" "}
                    {NEXT_STEP_TEXT}{" "}
                  </button>
                )}
              </div>
            </div>
          )}

          {/* SHIPPING SELECTION */}
          {step === 1 && Object.keys(selectedProducts).length > 0 && (
            <div className="purchase-step">
              <h3 style={{ marginTop: "auto" }}>Select your shipping method</h3>

              <ShippingSelection
                onShippingSelect={(shipping: string) => {
                  dispatch(setShippingMethod(shipping));
                  dispatch(nextStep(null));
                }}
                shippingOptions={
                  customShippingValues ? customShippingValues : shippingOptions
                }
                selectedShipping={shippingMethod}
              />

              {selectedProducts["fictitiousNameRenewal"] && (
                <div className="extra-shipping-message">
                  If you made changes to your owner information, you will be
                  required to also submit a legal notice advertisement which may
                  take an additional 1-2 weeks to be published, depending on the
                  day and time the order is received. Once your ad is published,
                  allow an additional 1-2 weeks to receive your copy and
                  notarized affidavit for electronic orders, and 2-3 weeks for
                  USPS orders.
                </div>
              )}

              <div className="button-step-wrapper">
                <button
                  className="step-btn btn back-btn"
                  onClick={() => dispatch(prevStep(null))}
                >
                  {" "}
                  {BACK_STEP_TEXT}{" "}
                </button>
              </div>
            </div>
          )}

          {/* PURCHASE FORM */}
          {Object.keys(selectedProducts).length > 0 && (
            <div
              className="purchase-step"
              style={step === 2 ? {} : { display: "none" }}
            >
              <div className="button-step-wrapper">
                {Object.keys(selectedProducts).length > 0 && (
                  <button
                    className="step-btn btn back-btn"
                    onClick={() => dispatch(prevStep(null))}
                  >
                    {" "}
                    {BACK_STEP_TEXT}{" "}
                  </button>
                )}
              </div>
              <h3>Enter your information</h3>

              <div className="form-pre-information">
                {selectedProducts["einTaxId"] && (
                  <div className="enter-information-correctly">
                    <u>Your information must match exactly</u> as on file with
                    the IRS and SSA. If this information submitted here does not
                    match the IRS records, this{" "}
                    <u>
                      <b>will cause delays</b>
                    </u>{" "}
                    in the processing of your EIN Application.
                  </div>
                )}

                {shippingMethod !== "physical" ? (
                  <div className="ordering-message">
                    YOU ARE ORDERING THIS DOCUMENT{" "}
                    <u>ELECTRONICALLY VIA EMAIL</u> <br />
                    <span className="red">
                      PLEASE MAKE SURE YOU ENTER YOUR EMAIL ADDRESS CORRECTLY.
                    </span>{" "}
                    <br />
                    <u>CHECK YOUR SPAM</u> FOLDER PERIODICALLY.
                  </div>
                ) : (
                  <div className="ordering-message">
                    YOU ARE ORDERING THIS DOCUMENT <u>USPS DELIVERY</u> <br />
                  </div>
                )}
              </div>

              <PurchaseForm
                key={specialPricingSet}
                onSubmit={submitForm}
                selectedProducts={selectedProducts}
                shippingMethod={shippingMethod}
                disableForm={loading === "pending"}
                prepaid={prepaid}
                initialFormOptions={{
                  annualBusinessType: specialPricingSet,
                }}
              />

              {message && <div className="page-message">{message}</div>}
              {error && (
                <div className="page-message error-message">
                  {JSON.stringify(error)}
                </div>
              )}
            </div>
          )}

          {/* UPSELL FORM */}
          {step === 3 && Object.keys(selectedProducts).length > 0 && (
            <div
              className="purchase-step"
              style={step === 3 ? {} : { display: "none" }}
            >
              <div className="button-step-wrapper">
                {Object.keys(selectedProducts).length > 0 && (
                  <button
                    className="step-btn btn back-btn"
                    onClick={() => dispatch(prevStep(null))}
                  >
                    {" "}
                    {BACK_STEP_TEXT}{" "}
                  </button>
                )}
              </div>

              {upsellProducts && Object.keys(upsellProducts).length > 0 && (
                <div className="product-cart-container">
                  <UpsellProduct
                    upsellProduct={
                      upsellProducts[Object.keys(upsellProducts)[0]]
                    }
                    selectedProducts={selectedProducts}
                    // updateCart={updateShoppingCart}
                    updateCart={updateShoppingCartUpsell}
                    proceedToCheckout={handleUpsellSubmit}
                    disabled={loading === "pending"}
                  />
                </div>
              )}

              <div className="cart-information">
                <CartInformation />

                {/* {step === 0 && Object.keys(selectedProducts).length > 0 && (
                    <button className="step-btn btn" onClick={() => dispatch(nextStep(null))}> {NEXT_STEP_TEXT} </button>
                )} */}
              </div>

              {message && <div className="page-message">{message}</div>}
              {error && (
                <div className="page-message error-message">
                  {JSON.stringify(error)}
                </div>
              )}
            </div>
          )}

          {/* BILLING AND SHIPPING FORM */}
          {step === 4 && Object.keys(selectedProducts).length > 0 && (
            <div
              className="purchase-step"
              style={step === 4 ? {} : { display: "none" }}
            >
              <div className="button-step-wrapper">
                {Object.keys(selectedProducts).length > 0 && (
                  <button
                    className="step-btn btn back-btn"
                    onClick={() => dispatch(prevStep(null))}
                  >
                    {" "}
                    {BACK_STEP_TEXT}{" "}
                  </button>
                )}
              </div>

              <BillingShippingForm
                requiresBilling={billingInfoRequired}
                requiresShipping={shippingAddressRequired}
                onSubmit={(data: any) => {
                  handleBillingShippingSubmit(data);
                }}
                disableForm={loading === "pending"}
              />

              {message && <div className="page-message">{message}</div>}
              {error && (
                <div className="page-message error-message">
                  {JSON.stringify(error)}
                </div>
              )}
            </div>
          )}

          {/* {Object.keys(extraOfferings).length > 0 && step === 0 && (
              <div className="extra-offering-container">
              <hr />
              {extraOfferings.map((offer, idx) => (
              <div className="purchase-step" key={`${offer.title}-${idx}`}>
              {offer.title && (<h1>{offer.title}</h1>)}
              {offer.subtitle && (<h3>{offer.subtitle}</h3>)}
              <ProductSelection
              updateCart={(clickedProduct) => {
              const selectedOffer = offer.offers[clickedProduct]
                if (selectedOffer.linkTo) {
                window.location.href = selectedOffer.linkTo
                }
                }}
                selectedProducts={{}}
                productValues={offer.offers} />
              </div>
              ))}
              </div>
        )} */}
        </div>
      </div>
    </div>
  );
}
