import { Form, Formik } from "formik"
import { useEffect, useMemo, useRef } from "react";
import { sitePhrasing } from "../../../Language/languageHandler";
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField";
import TextInputField from "../../FormFields/TextInputField/TextInputField";
import { AnnualReportFields } from "./AnnualReportFields";
import { EinTaxFields } from "./EinTaxFields";
import { LegalNoticeAdvertisementFields } from "./LegalNoticeAdvertisementFields";
import { generalAgreementText, iAgreeText } from "./PurchaseConstants";
import { generatePurchaseFormInitialValues, generatePurchaseValidationSchema } from "./PurchaseFormHelpers";
import { FictitiousNameRenewalFields } from "./FictitiousNameRenewalFields";
import { ContactInfoFields } from "./ContactInfoFields";
import { AddressInputFieldGroup } from "../../FormFields/AddressFieldGroup/AddressInputFieldGroup";

export default function PurchaseForm(props: any) {

  const {
    onSubmit,
    selectedProducts,
    disableForm,
    initialFormOptions = {}
  } = props
  const validationSchemaFunction = useMemo(() => {
    const vFn = generatePurchaseValidationSchema(selectedProducts)
    return vFn
  }, [selectedProducts])
  const formikRef: any = useRef(null);
  useEffect(() => {
    if (formikRef && formikRef.current && formikRef.current.validateForm) {
      formikRef.current.validateForm()
    }
  }, [selectedProducts]);

  const scrollToError = () => {

    const silentErrors = document.querySelectorAll('.silent-error')
    if (silentErrors && silentErrors.length > 0) {
      silentErrors.forEach((x: any) => {
        if (x.focus && x.blur) {
          x.focus()
          x.blur()
        }
      })
    }

    const el: any = document.querySelector('.error');
    if (el) {
      (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
      return
    }
    const sel: any = document.querySelector('.silent-error');
    if (sel) {
      if (sel.focus && sel.blur) {
        sel.focus();
        sel.blur();
        sel.focus();
      }
      (sel?.parentElement ?? sel)?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  let useGeneralAgreementText = false

  if (
    (selectedProducts['laborLawPoster'] && Object.keys(selectedProducts).length === 1) ||
    (selectedProducts['certificateOfStatus'] && Object.keys(selectedProducts).length === 1) ||
    (selectedProducts['laborLawPoster'] && selectedProducts['certificateOfStatus'] && Object.keys(selectedProducts).length === 2)
  ) {
    useGeneralAgreementText = true
  }

  const annualSelected = selectedProducts['annualReport'] || selectedProducts['amend'] || selectedProducts['reinstate']

  return (
    <>
      <div className="purchase-form-container">
        <Formik
          innerRef={formikRef}
          initialValues={generatePurchaseFormInitialValues(selectedProducts, initialFormOptions)}
          validationSchema={validationSchemaFunction}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
            onSubmit(values)
          }}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            // setValues,
          }) => (
            <div className="purchase-form">
              <Form>

                {!selectedProducts['fictitiousNameRenewal'] &&
                  !selectedProducts['einTaxId'] &&
                  !(annualSelected && sitePhrasing.state === 'Tennessee') &&
                  !(annualSelected && sitePhrasing.state === 'Florida') && (
                    <ContactInfoFields
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}

                {!selectedProducts['fictitiousNameRenewal'] &&
                  !(annualSelected && sitePhrasing.state === 'Florida') && (
                    <TextInputField
                      mask={sitePhrasing.referenceMask}
                      fieldName={'referenceNumber'}
                      fieldLabel={'Reference Number'}
                      fieldSubLabel={'(This number can be found on the top right hand corner of your letter)'}
                      errors={errors}
                      touched={touched}
                      required={true}
                      placeholder={'Reference Number'}
                    />
                  )}

                {!selectedProducts['fictitiousNameRenewal'] &&
                  !selectedProducts['einTaxId'] &&
                  !selectedProducts['legalNoticeAdvertisement'] &&
                  !(annualSelected && sitePhrasing.state === 'Florida') && (
                    <TextInputField
                      fieldName={'businessName'}
                      fieldLabel={'Complete Business Name'}
                      errors={errors}
                      touched={touched}
                      required={true}
                      placeholder={'Complete Business Name'}
                    />
                  )}

                {sitePhrasing.state === 'Tennessee' &&
                  selectedProducts['certificateOfStatus'] && 
                  !selectedProducts['fictitiousNameRenewal'] &&
                  !selectedProducts['einTaxId'] &&
                  !selectedProducts['legalNoticeAdvertisement'] &&
                  !annualSelected && (
                    <AddressInputFieldGroup
                      fieldName={'generalAddress'}
                      errors={errors}
                      touched={touched}
                    />
                  )}

                {selectedProducts['fictitiousNameRenewal'] && (
                  <FictitiousNameRenewalFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    selectedProducts={selectedProducts}
                  />
                )}

                {selectedProducts['legalNoticeAdvertisement'] && !selectedProducts['fictitiousNameRenewal'] && (
                  <LegalNoticeAdvertisementFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}

                {selectedProducts['einTaxId'] && (
                  <EinTaxFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />
                )}

                {(selectedProducts['annualReport'] || selectedProducts['amend'] || selectedProducts['reinstate']) && (
                  <AnnualReportFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    selectedProducts={selectedProducts}
                    setFieldValue={setFieldValue}
                  />
                )}

                {useGeneralAgreementText && (
                  <>
                    <CheckboxInputField
                      fieldName={'generalAgree'}
                      fieldLabel={generalAgreementText}
                      touched={touched}
                      errors={errors}
                    />
                    <TextInputField
                      fieldName={'electronicSignature'}
                      fieldLabel={'Client Agreement & Signature'}
                      errors={errors}
                      touched={touched}
                      required={true}
                      placeholder={'Electronic Signature'}
                      customClass={'signature-font'}
                    />
                  </>
                )}

                <div className="final-purchase-form-section">
                  <div className="text-center">
                    <h2 className="red-text">Before Proceeding</h2>
                    <p style={{ fontSize: '0.9em' }}>
                      Please be fully aware of our <a className="link-text" target="_blank" href="/refund-policy/">refund policy</a>, <a className="link-text" target="_blank" href="/privacy-policy/">privacy policy</a>, and <a className="link-text" target="_blank" href="/terms-of-service/">terms of service</a> before proceeding.
                    </p>
                    <p>
                      <b>
                        All Payments are final.
                      </b>
                    </p>
                  </div>

                  <CheckboxInputField
                    fieldName={'iAgree'}
                    fieldLabel={iAgreeText}
                    touched={touched}
                    errors={errors}
                  />

                  <div className="form-btn-container">
                    <button type="submit"
                      className={`submit-btn btn ${disableForm || isSubmitting || Object.keys(errors).length > 0 ? 'disabled-btn' : ''}`}
                      disabled={disableForm || isSubmitting || Object.keys(errors).length > 0}>
                      Proceed to Checkout
                    </button>

                    {errors && Object.keys(errors).length > 0 && (
                      <div className="field-container error-btn-container">
                        <div className="form-error form-error-btn" onClick={scrollToError}>
                          <b>There are errors with the form. Click here to fix</b>
                          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>


              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  )
}
