export default function ShippingSelection(props: {
  onShippingSelect: (s: string) => void,
  selectedShipping: string,
  shippingOptions: {
    [sId: string]: {
      id: string,
      name: string,
      amount: number,
      subText1: string,
      subText2: string,
    }
  }
}) {
  const {
    onShippingSelect,
    selectedShipping,
    shippingOptions,
  } = props

  return (
    <div className="shipping-selection-container">
      {shippingOptions && Object.keys(shippingOptions).map((s: string, idx: number) => (
        <div
          key={`${s}-${idx}`}
          onClick={() => { onShippingSelect(s) }}
          className={`shipping-selection ${selectedShipping === s ? 'selected' : ''}`}>
          <div className="shipping-name">{shippingOptions[s].name} </div>
          <div className="shipping-subtext-1">{shippingOptions[s].subText1} </div>
          <div className="shipping-subtext-2">{shippingOptions[s].subText2} </div>

          {/*shippingOptions[s].amount > 0 ? <> - ${shippingOptions[s].amount/100} extra</> : null*/}
        </div>
      ))}
    </div>
  )
}
