import { productValues, updateCartInfo, shippingOptions, extraOfferings } from "./Shopping/shoppingHelpers"
import { shoppingInitialState } from "./Shopping/shoppingSlice"

declare global {
  interface Window {
    initialSelection: {productId: string}[]
    hideOptions: {productId: string}[]
    shippingSelection: string
    customPricing: {productId: string, price: number, name?: string}[]
    initialStep: number;
    prepaidMode: boolean;
    extraOffers: any[];
  }
}
export const getInitialState = () => {
  const initialState: any = {
    shopping: shoppingInitialState()
  }

  if(window.hideOptions) {
    window.hideOptions.forEach((x) => {
      if(productValues[x.productId]) {
        delete productValues[x.productId]
      }
    })
  }

  if(window.customPricing) {
    window.customPricing.forEach((x) => {
      if(productValues[x.productId]) {
        // productValues[x.productId].price = x.price
        productValues[x.productId] = {
          ...productValues[x.productId],
          ...x
        }
      } else if (x.productId && x.name && x.price) {
        productValues[x.productId] = {
          ...x
        }
      }
    })
  }

  if(window.prepaidMode) {
    Object.keys(productValues).forEach((x) => {
      productValues[x].price = 0
    })
    Object.keys(shippingOptions).forEach((x) => {
      shippingOptions[x].amount = 0
    })
    initialState.shopping.prepaid = true
  }

  if(window.extraOffers) {
    window.extraOffers.forEach((offer) => {
      extraOfferings.push(offer)
    })
  }
  
  if(window.initialSelection) {
    window.initialSelection.forEach(x => {
      initialState.shopping = updateCartInfo(initialState.shopping, {
        product: x.productId
      })
    })
  }
  if(window.shippingSelection) {
    initialState.shopping = updateCartInfo(initialState.shopping, {
      shipping: window.shippingSelection
    })
  }

  if(window.initialStep) {
    initialState.shopping.step = window.initialStep;
  }
  
  return initialState
}
